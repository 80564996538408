

































































import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations, SetSelectedEntities } from '@/store/report/mutations.report';
import { ParentResultHash } from '@/store/report/report';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { MutationMethod, ActionMethod } from 'vuex';
import { Getter, Mutation, Action } from 'vuex-class';
import Excerpt from '../Excerpt.vue';
import SelectEntityView from '../SelectEntityView.vue';
import FilterHeader from './FilterHeader.vue';

@Component({ components: { FilterHeader, SelectEntityView, Excerpt } })
export default class FilterMarketViewEntities extends Vue {
  editMenu: boolean[] = [];
  addDialog = false;
  resultMenu = false;
  searchTerm = '';
  entitySelection: SetSelectedEntities | null = null;

  timeout: NodeJS.Timeout | null = null;

  @Mutation(`report/${ReportMutations.SET_SELECTED_ENTITIES}`)
  setSelectedEntities!: MutationMethod;
  @Mutation(`report/${ReportMutations.REMOVE_SELECTED_PARENT}`)
  removeSelectedParent!: MutationMethod;

  @Getter(`report/${ReportGetter.GET_SELECTED_PARENTS}`)
  parents!: ParentResultHash;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  @Watch('parents', { deep: true })
  onParentsChange() {
    this.$forceUpdate();
  }

  onAddEntity() {
    this.addDialog = true;
  }

  onMenuExcerptChange(parentId: number, selection: number[]) {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.timeout = setTimeout(() => {
      const mutationPayload: SetSelectedEntities = {
        parentId,
        selection,
      };
      this.setSelectedEntities(mutationPayload);
      this.performFetchReport();
    }, 125);
  }

  onSelection(selection: SetSelectedEntities) {
    this.entitySelection = selection;
  }

  onParentCloseClick(id: number) {
    this.removeSelectedParent(id);
    this.$forceUpdate();
    this.performFetchReport();
  }

  onAdd() {
    this.addDialog = false;
    this.setSelectedEntities(this.entitySelection);
    this.$forceUpdate();
    this.performFetchReport();
  }
}
