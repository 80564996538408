







































import { FetchOwlTreeNodesQueryVariables, Scope, TreeNode } from '@/generated/graphql';
import { ReportMutations, SetSelectedEntities } from '@/store/report/mutations.report';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';
import SearchResultTable from '@/components/report/SearchResultTable.vue';
import { ReportActions } from '@/store/report/actions.report';
import { ActionMethod } from '@/helpers/typeHelpers';
import Excerpt from './Excerpt.vue';
import { ReportGetter } from '@/store/report/getter.report';
import { ParentResultHash } from '@/store/report/report';

export type DataType = {
  dateRange: Array<string>;
};

@Component({
  components: { SearchResultTable, Excerpt },
  methods: {},
})
export default class SelectParentView extends Vue {
  searchTimeout: NodeJS.Timeout | null = null;
  parents: TreeNode[] = [];
  selectedParent: TreeNode | null = null;
  showResultMenu = false;
  searchTerm = '';
  selectedEntities: TreeNode[] = [];

  @Prop({ required: true })
  visible!: boolean;

  get preSelection(): number[] {
    if (this.selectedParent) {
      return this.selectedParents[this.selectedParent?.id].selected;
    }
    return [];
  }

  onEntitySelectionChange(event: number[]) {
    const eventPayload: SetSelectedEntities = {
      parentId: this.selectedParent!.id,
      selection: event,
    };
    this.$emit('select', eventPayload);
  }

  @Mutation(`report/${ReportMutations.SET_SELECTED_PARENT}`)
  setSelectedParent!: MutationMethod;

  @Getter(`report/${ReportGetter.GET_SELECTED_PARENTS}`)
  selectedParents!: ParentResultHash;

  @Action(`report/${ReportActions.FETCH_OWL_TREENODES}`)
  performSearchParents!: ActionMethod<FetchOwlTreeNodesQueryVariables, TreeNode[]>;

  onSelect(value: TreeNode) {
    this.setSelectedParent(value);
    this.selectedParent = value;
    this.showResultMenu = false;
  }

  @Watch('visible')
  onVisibleChange() {
    this.selectedParent = null;
    this.searchTerm = '';
  }

  async doSearch() {
    const payload: FetchOwlTreeNodesQueryVariables = {
      q: this.searchTerm,
      type: [Scope.Parent],
    };
    this.openResultMenu();
    this.parents = await this.performSearchParents(payload);
  }
  openResultMenu() {
    if (this.searchTerm.length >= 3) this.showResultMenu = true;
  }

  @Watch('searchTerm')
  onSearchChange(to: string) {
    if (to !== null && to.length >= 3) {
      if (this.searchTimeout != null) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.doSearch();
        this.searchTimeout = null;
      }, 1000);
    }
  }
}
