









import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import FilterSidebar from '@/components/report/FilterSidebar.vue';
import Excerpt from '@/components/report/Excerpt.vue';
import ReportResultTabs from '@/components/report/ReportResultTabs.vue';

@Component({
  components: { FilterSidebar, Excerpt, ReportResultTabs },
  methods: {},
})
export default class Report extends Vue {
  mounted(): void {
    //@ts-ignore
    this.$gtag.pageview('/report');
  }

  onFilterChange() {
    console.log('onFilterChange');
  }
}
