



























import Vue from 'vue';
import Component from 'vue-class-component';
import CommonFilterChips from './CommonFilterChips.vue';
import ViewMoreOrLess from './ViewMoreOrLess.vue';
import { Prop } from 'vue-property-decorator';
import { ReportActions } from '@/store/report/actions.report';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';

@Component({ components: { CommonFilterChips, ViewMoreOrLess } })
export default class CommonFilterCheckbox extends Vue {
  viewMore = false;

  @Prop({ default: () => [] })
  items!: { [key: string]: number | string }[];

  @Prop({ default: () => [] })
  selected!: { [key: string]: number | string }[];

  @Prop({ default: false })
  expand!: boolean;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  get displayChips() {
    return !this.expand && this.selected && this.selected.length;
  }

  get displayViewMore() {
    return !this.viewMore && this.items.length > this.list.length;
  }

  get list() {
    if (this.items.length > 5 && !this.viewMore) return this.items.slice(0, 5);
    return this.items;
  }

  get selectedItems() {
    return this.selected;
  }

  set selectedItems(event) {
    this.$emit('onSelectChange', event);
    this.performFetchReport();
  }

  async onCloseClick(code: string) {
    this.$emit('closeClick', code);
  }

  toggleViewMore(v: boolean) {
    this.viewMore = v;
  }
}
