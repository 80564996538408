




import { Prop } from 'vue-property-decorator';

import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getStateColors, getStateNames } from '@/helpers/reportingHelper';
import ApexChart from 'vue-apexcharts';
import { ApexOptions } from 'apexcharts';
import { DEFAULT_CHART_OPTIONS } from '@/constants';

@Component({
  components: { ApexChart },
  methods: {},
})
export default class DonutChart extends Vue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: () => [] })
  series!: unknown[];

  get chartOptions(): ApexOptions {
    return {
      ...DEFAULT_CHART_OPTIONS,
      labels: getStateNames(),
      title: {
        text: this.title,
        align: 'left',
      },
      colors: getStateColors(),
      theme: {
        mode: 'dark',
      },
      stroke: {
        width: 0,
      },
      chart: {
        background: 'transparent',
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      tooltip: {
        fillSeriesColor: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                formatter: function(name: string) {
                  return name;
                },
              },
              value: {
                formatter: function(val: string) {
                  return val;
                },
              },
              total: {
                show: true,
                showAlways: false,
                label: 'Total',
                color: 'white',
                /*eslint-disable */
                formatter: function(w: any): string {
                  return w.globals.seriesTotals.reduce((a: number, b: number) => {
                    return a + b;
                  }, 0);
                },
                /*eslint-enable */
              },
            },
          },
        },
      },
      legend: {
        position: 'right',
      },
      fill: {
        opacity: 1,
      },
    };
  }
}
