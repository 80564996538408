var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showAll)?_c('v-row',[_c('v-col',{attrs:{"offset":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search.search'),"filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"ml-2 pt-1"},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Glossary")])]),_c('v-list',{staticClass:"column_wrapper"},[_vm._l((_vm.glossary),function(item,i){return [_c('v-list-item',{key:i,staticClass:"glossary-item"},[_c('b',{staticClass:"mr-1"},[_vm._v(_vm._s(item.abbr))]),_vm._v(" "+_vm._s(item.full))])]})],2)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"certifications elevation-0 row-pointer",attrs:{"search":_vm.search,"dense":"","data-cy":"test-certResult-table","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.showAll ? _vm.items.length : 15,"disable-pagination":_vm.showAll,"hide-default-footer":_vm.showAll},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-cy":"test-integration-name"}},[_vm._v(_vm._s(item.name))]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.app_id))])]}},{key:"item.parent_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.parent_name))]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.mve_name)+" ("+_vm._s(item.mve_type == 'brand' ? 'B' : 'C')+")")])]}},{key:"item.client_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.client_id))]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.vcid))])]}},{key:"item.device_type_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.device_type_name)+" "+_vm._s(_vm.osToCharacter(item.os)))])]}},{key:"item.region",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.region.toUpperCase()))])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("products." + (item.product)))))])]}},{key:"item.state_type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getCssClassFromState(item.state_type)},[_vm._v(_vm._s(_vm.$t(("workflow." + (item.state_type)))))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCondensedTime(item.created_at)))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }