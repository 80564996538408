

































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { getOsArray } from '@/helpers/osHelper';
import {
  Country,
  FetchDogCountriesQuery,
  FetchDogCountriesQueryVariables,
  Integration,
  ReportFilter,
  DogStatus,
} from '@/generated/graphql';
import { CoreGetter } from '@/store/core/getter.core';
import { CoreActions } from '@/store/core/actions.core';
import { ActionMethod } from '@/helpers/typeHelpers';
import { getProductArray } from '@/helpers/productHelper';
import DateRangePickerMenu from '@/components/common/DateRangePickerMenu.vue';
import FilterMenu from '@/components/report/filter/FilterMenu.vue';
import FilterProducts from '@/components/report/filter/FilterProducts.vue';
import FilterDateRange from '@/components/report/filter/FilterDateRange.vue';
import FilterDeviceTypes from '@/components/report/filter/FilterDeviceTypes.vue';
import FilterOs from '@/components/report/filter/FilterOs.vue';
import FilterStatus from '@/components/report/filter/FilterStatus.vue';
import FilterCounties from '@/components/report/filter/FilterCountries.vue';
import FilterMarketViewEntities from '@/components/report/filter/FilterMarketViewEntities.vue';
import { ReportGetter } from '@/store/report/getter.report';

export type FilterResult = {
  searchTerm: string;
  search: Integration | null;
  os: string;
  country: Country | null;
  products: Array<{ name: string; code: string }>;
  dateRange: Array<string>;
  assignee: string;
  status: DogStatus | null;
};

@Component({
  components: {
    DateRangePickerMenu,
    FilterMenu,
    FilterMarketViewEntities,
    FilterCounties,
    FilterOs,
    FilterDeviceTypes,
    FilterDateRange,
    FilterProducts,
    FilterStatus,
  },
  methods: { getOsArray, getProductArray },
})
export default class FilterBar extends Vue {
  filter: FilterResult = {
    searchTerm: '',
    search: null,
    os: '',
    country: null,
    products: [],
    dateRange: [],
    assignee: '',
    status: null,
  };

  expandComponents: {
    [name: string]: boolean;
  } = {
    countries: false,
    date: false,
    os: false,
    deviceTypes: false,
    products: false,
    status: false,
  };

  showParentResultMenu = false;

  @Getter(`core/${CoreGetter.GET_COUNTRIES}`)
  countries!: Country[];

  @Action(`core/${CoreActions.FETCH_COUNTRIES}`)
  performFetchCountries!: ActionMethod<FetchDogCountriesQueryVariables, FetchDogCountriesQuery>;

  mounted() {
    this.performFetchCountries();
  }

  @Getter(`report/${ReportGetter.GET_LOADED_FILTER}`)
  loadedFilter!: ReportFilter;

  @Watch('filter', { deep: true })
  async onSearch(f: FilterResult) {
    this.$emit('input', f);
  }

  @Watch('filter.searchTerm', { deep: true })
  async onSearchTermChange() {
    this.showParentResultMenu = true;
  }

  toggleExpand(payload: { expand: boolean; filter: string }) {
    this.expandComponents[payload.filter] = payload.expand;
    if (payload.expand) this.minimizeFilters(payload.filter);
  }

  minimizeFilters(filter: string) {
    Object.keys(this.expandComponents).forEach(f => {
      if (f !== filter) this.expandComponents[f] = false;
    });
  }
}
