













import Vue from 'vue';
import Component from 'vue-class-component';
import FilterHeader from './FilterHeader.vue';
import CommonFilterCheckbox from './CommonFilterCheckbox.vue';
import { MutationMethod, ActionMethod } from 'vuex';
import { DogStatus } from '@/generated/graphql';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { Action, Getter, Mutation } from 'vuex-class';
import { getStatusArray } from '@/helpers/osHelper';
import { Prop } from 'vue-property-decorator';

@Component({ components: { FilterHeader, CommonFilterCheckbox } })
export default class FilterStatus extends Vue {
  statusDetails = getStatusArray();

  @Prop({ default: false })
  expand!: boolean;

  @Getter(`report/${ReportGetter.GET_SELECTED_STATUS}`)
  selectedStatus!: { name: DogStatus }[];

  @Mutation(`report/${ReportMutations.SET_SELECTED_STATUS}`)
  setSelectedStatus!: MutationMethod;

  @Mutation(`report/${ReportMutations.REMOVE_SELECTED_STATUS}`)
  removeSelectedStatus!: MutationMethod;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  onExpand(e: boolean) {
    this.$emit('toggleExpand', { expand: e, filter: 'status' });
  }

  onCloseClick(name: string) {
    this.removeSelectedStatus(name);
    this.performFetchReport();
  }
}
