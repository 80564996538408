













import Vue from 'vue';
import Component from 'vue-class-component';
import FilterHeader from './FilterHeader.vue';
import CommonFilterCheckbox from './CommonFilterCheckbox.vue';
import { MutationMethod, ActionMethod } from 'vuex';
import { Os } from '@/generated/graphql';
import { TranslateResult } from 'vue-i18n';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { Action, Getter, Mutation } from 'vuex-class';
import { getOsArray } from '@/helpers/osHelper';
import { Prop } from 'vue-property-decorator';

@Component({ components: { FilterHeader, CommonFilterCheckbox } })
export default class FilterOs extends Vue {
  operatingSystems = getOsArray();

  @Prop({ default: false })
  expand!: boolean;

  @Getter(`report/${ReportGetter.GET_SELECTED_OS}`)
  selectedOs!: { code: Os; name: TranslateResult }[];

  @Mutation(`report/${ReportMutations.SET_SELECTED_OS}`)
  setSelectedOs!: MutationMethod;

  @Mutation(`report/${ReportMutations.REMOVE_SELECTED_OS}`)
  removeSelectedOs!: MutationMethod;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  onExpand(e: boolean) {
    this.$emit('toggleExpand', { expand: e, filter: 'os' });
  }

  onCloseClick(code: string) {
    this.removeSelectedOs(code);
    this.performFetchReport();
  }
}
