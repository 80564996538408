


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CommonFilterChips extends Vue {
  @Prop({ default: () => [] })
  selected!: { [key: string]: number | string }[];

  @Prop({ default: false })
  expand!: boolean;

  get displayChips() {
    return !this.expand && this.selected && this.selected.length;
  }

  async onCloseClick(code: string) {
    this.$emit('close', code);
  }
}
