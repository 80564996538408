


























import { ReportGetter } from '@/store/report/getter.report';
import { ReportType } from '@/store/report/report';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import BarChart from './charts/BarChart.vue';
import DonutChart from './charts/DonutChart.vue';
import LineChart from './charts/LineChart.vue';
import StateDeviceTypeTable from './StateDeviceTypeTable.vue';
import Vue from 'vue';

@Component({
  components: { BarChart, DonutChart, LineChart, StateDeviceTypeTable },
  methods: {},
})
export default class ReportResult extends Vue {
  @Getter(`report/${ReportGetter.GET_REPORT}`)
  report!: ReportType;
}
