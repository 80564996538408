
























































































import { CUSTOM_DATE_RANGE_NAME } from '@/constants';
import { DeviceTypeGroup, FetchFiltersQuery, FetchFiltersQueryVariables, ReportFilter } from '@/generated/graphql';
import { ActionMethod } from '@/helpers/typeHelpers';
import { CoreGetter } from '@/store/core/getter.core';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { ParentResultHash } from '@/store/report/report';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

@Component({
  computed: {
    customDateRange() {
      return CUSTOM_DATE_RANGE_NAME;
    },
  },
})
export default class LoadFilterDialog extends Vue {
  loading = false;
  loadedFilters: Pick<ReportFilter, 'id' | 'name' | 'filters'>[] = [];
  search = '';
  onlyCurrentUser = true;
  @Getter(`report/${ReportGetter.SHOW_LOAD_FILTER_DIALOG}`)
  show!: boolean;

  @Getter(`core/${CoreGetter.GET_COUNTRIES}`)
  countries!: ParentResultHash;
  @Getter(`report/${ReportGetter.GET_DEVICE_TYPES}`)
  deviceTypeGroups!: DeviceTypeGroup[];

  @Mutation(`report/${ReportMutations.SHOW_LOAD_FILTER_DIALOG}`)
  showDialog!: MutationMethod;

  @Action(`report/${ReportActions.SET_SAVED_FILTER}`)
  setSavedFilter!: ActionMethod<ReportFilter, Promise<boolean>>;
  @Action(`report/${ReportActions.FETCH_REPORT_FILTERS}`)
  fetchReportFilters!: ActionMethod<FetchFiltersQueryVariables, FetchFiltersQuery>;

  get filters() {
    if (this.search.length > 0) {
      return this.loadedFilters.filter(f => f.name?.indexOf(this.search) !== -1);
    }
    return this.loadedFilters;
  }

  @Watch('onlyCurrentUser')
  @Watch('show')
  async onChange() {
    if (!this.show) {
      this.search = '';
      return;
    }
    setTimeout(() => {
      // @ts-ignore
      this.$refs.search.focus();
    }, 500);
    this.$forceUpdate();
    this.loading = true;
    const response = await this.fetchReportFilters({ currentUser: this.onlyCurrentUser });
    this.loadedFilters = response.fetchFilters
      .slice()
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    this.loading = false;
  }

  async onSelect(filter: ReportFilter) {
    if (filter.filters) {
      this.loading = true;
      await this.setSavedFilter(filter);
      this.showDialog(false);
      this.reset();
      // @ts-ignore
      /* eslint-disable @typescript-eslint/camelcase */
      this.$gtag.event('load_filter', {
        event_category: 'report',
        event_label: 'load report filter template',
      });
    }
  }

  reset() {
    this.onlyCurrentUser = true;
    this.loading = false;
    this.search = '';
  }
}
