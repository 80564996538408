













import Vue from 'vue';
import Component from 'vue-class-component';
import FilterHeader from './FilterHeader.vue';
import CommonFilterCheckbox from './CommonFilterCheckbox.vue';
import { MutationMethod, ActionMethod } from 'vuex';
import { Country } from '@/generated/graphql';
import { CoreGetter } from '@/store/core/getter.core';
import { CoreActions } from '@/store/core/actions.core';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { Getter, Action, Mutation } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

@Component({ components: { FilterHeader, CommonFilterCheckbox } })
export default class FilterCountries extends Vue {
  @Prop({ default: false })
  expand!: boolean;

  created() {
    if (!this.countries?.length) {
      this.performFetchCountries();
    }
  }

  @Action(`core/${CoreActions.FETCH_COUNTRIES}`)
  performFetchCountries!: ActionMethod;

  @Getter(`core/${CoreGetter.GET_SORTED_COUNTRIES}`)
  countries!: Country[];

  @Getter(`report/${ReportGetter.GET_SELECTED_COUNTRIES}`)
  selectedCountry!: Country[];

  @Mutation(`report/${ReportMutations.SET_SELECTED_COUNTRIES}`)
  setSelectedCountry!: MutationMethod;

  @Mutation(`report/${ReportMutations.REMOVE_SELECTED_COUNTRY}`)
  removeSelectedCountry!: MutationMethod;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  onExpand(e: boolean) {
    this.$emit('toggleExpand', { expand: e, filter: 'countries' });
  }

  onCloseClick(code: string) {
    this.removeSelectedCountry(code);
    this.performFetchReport();
  }
}
