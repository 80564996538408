




























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { NlsnTreeViewItem } from './TreeView.vue';

export interface NlsnTreeViewItemChangeEvent {
  item: NlsnTreeViewItem;
  event: boolean;
}

@Component({ name: 'NlsnTreeViewGroup' })
export default class NlsnTreeViewGroup extends Vue {
  value = false;

  $refs!: {
    childs: NlsnTreeViewGroup[];
  };

  @Prop({ default: () => [] })
  preSelect!: number[];
  @Prop({ required: true })
  item!: NlsnTreeViewItem;
  @Prop({ default: () => 0 })
  depth!: number;
  @Prop({ default: () => '' })
  search!: string;
  @Prop({ default: () => false })
  selectAllChildren!: boolean;

  get showAddAllButton(): boolean {
    return this.item.children && this.item.children.length > 0;
  }

  @Watch('preSelect', { immediate: true })
  onPreSelectChange() {
    if (this.preSelect.indexOf(this.item.id) !== -1) {
      this.value = true;
    }
  }

  @Watch('value', { immediate: true })
  onChange(to: boolean, from: boolean) {
    if (to !== from) this.$emit('change', [{ item: this.item, event: to }]);
  }

  calculateDepth() {
    return `ml-${this.depth + 2}`;
  }

  updateChildren(value: boolean) {
    this.value = value;
    if (this.item.children && this.item.children.length > 0) {
      this.$refs.childs.forEach(ref => {
        ref.updateChildren(value);
      });
    }
  }

  async changeChildren(value: boolean) {
    const result: NlsnTreeViewItemChangeEvent[] = [];
    this.updateChildren(value);
    result.push(...this.getChildrenForEvent(this.item, value).flat());
    this.$emit('change', result);
  }

  getChildrenForEvent(item: NlsnTreeViewItem, event: boolean): NlsnTreeViewItemChangeEvent[] {
    const result: NlsnTreeViewItemChangeEvent[] = [];
    result.push({ event: event, item: item });
    if (!item.children) return result;
    item.children.forEach(child => {
      result.push(...this.getChildrenForEvent(child, event).flat());
    });
    return result;
  }

  onChildChange(items: NlsnTreeViewItemChangeEvent[]) {
    this.$emit('change', items);
  }
}
