

















import { getCssClassFromState } from '@/helpers/integrationHelper';
import { generateTotals, TableData } from '@/helpers/reportingHelper';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ methods: { getCssClassFromState } })
export default class StateDeviceTypeTable extends Vue {
  @Prop({ default: () => null })
  items!: TableData[] | null;

  @Prop({ default: () => null })
  deviceTypes!: string[] | null;

  get itemsCalculated(): TableData[] {
    if (this.items) {
      return generateTotals(JSON.parse(JSON.stringify(this.items)));
    }
    return [];
  }

  getCorrectStateName(smState: string): string {
    const t = this.$t(`smStates.${smState}`).toString();
    if (!t.indexOf('smStates')) {
      return smState;
    }
    return t;
  }

  get headers(): unknown[] {
    if (!this.deviceTypes) return [];
    const result: unknown[] = [
      {
        text: this.$t('state'),
        align: 'start',
        sortable: false,
        value: 'name',
        divider: true,
      },
    ];
    this.deviceTypes.forEach((d, i) => {
      const row = { text: d, value: d, align: 'center', divider: false };
      if (i === this.deviceTypes!.length - 1) {
        row.divider = true;
      }
      result.push(row);
    });
    result.push({ text: this.$t('total'), value: 'total', align: 'center' });
    return result;
  }
}
