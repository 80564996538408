



























































import { ReportGetter } from '@/store/report/getter.report';
import { ParentResultHash, ReportType, PdfRowType } from '@/store/report/report';
import { Component } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import FullScreenLoading from '@/components/common/FullScreenLoading.vue';
import BarChart from './charts/BarChart.vue';
import DonutChart from './charts/DonutChart.vue';
import LineChart from './charts/LineChart.vue';
import StateDeviceTypeTable from './StateDeviceTypeTable.vue';
import CertificationTable from './CertificationTable.vue';
import { MutationMethod } from 'vuex';
import { ReportMutations } from '@/store/report/mutations.report';
import { defaultDateTimeFormat } from '@/mixins/date';
import { DeviceTypeGroup } from '@/generated/graphql';
import Vue from 'vue';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  name: 'PrintDialog',
  components: { CertificationTable, BarChart, DonutChart, LineChart, StateDeviceTypeTable, FullScreenLoading },
})
export default class PrintDialog extends Vue {
  @Mutation(`report/${ReportMutations.SHOW_EXPORT_DIALOG}`)
  showExportDialog!: MutationMethod;

  @Getter(`report/${ReportGetter.SHOW_EXPORT_DIALOG}`)
  showDialog!: boolean;
  @Getter(`report/${ReportGetter.GET_SELECTED_PARENTS}`)
  parents!: ParentResultHash;
  @Getter(`report/${ReportGetter.GET_SELECTED_DATE}`)
  dateRange!: { startDate: string; endDate: string };
  @Getter(`report/${ReportGetter.GET_REPORT}`)
  report!: ReportType;
  @Getter(`report/${ReportGetter.GET_DEVICE_TYPES}`)
  devices!: DeviceTypeGroup[];
  @Getter(`report/${ReportGetter.GET_INDIVIDUAL_DEVICE_TYPES}`)
  individualDeviceTypes!: { [key: string]: string[] };

  loading = false;
  _exportSort = 'parent_name';

  mounted() {
    this.exportSort = 'parent_name';
  }

  get exportSort(): string {
    return this._exportSort;
  }

  set exportSort(val: string) {
    this._exportSort = val;
  }

  get dateRangeFormated(): unknown {
    return {
      start: this.$moment(this.dateRange.startDate).format(defaultDateTimeFormat),
      end: this.$moment(this.dateRange.endDate).format(defaultDateTimeFormat),
    };
  }

  get show(): boolean {
    return this.showDialog;
  }

  preparePDFRows(groupTables: { [key: string]: string[] }, deviceGroupName: string, sortBy: string) {
    const rows: PdfRowType[][] = [];

    // only return certifications within device type group e.g. "android phone" in group "mobile" or "apple tv" in group "ctv"
    const groupFilter = this.report.certifications.filter(d => {
      return groupTables[deviceGroupName].indexOf(d.device_type_name.toLowerCase()) > -1;
    });

    const sorter =
      sortBy == 'parent_name'
        ? (a: string, b: string) => a?.localeCompare(b) || -1
        : (a: string, b: string) => b?.localeCompare(a) || -1;

    if (groupFilter.length) {
      groupFilter
        .sort((a, b) => {
          const current = a[sortBy];
          const next = b[sortBy];

          return sorter(current, next);
        })
        .forEach(element => {
          const styles = this.getStyles(element);
          const temp = [
            { content: element.name + '\n' + element.app_id },
            {
              content: element.parent_name + '\n' + element.mve_name + (element.mve_type == 'brand' ? ' (B)' : ' (C)'),
            },
            { content: element.client_id + '\n' + `(${element.vcid})` },
            { content: element.device_type_name + this.osToCharacter(element.os) },
            { content: element.region.toUpperCase() },
            { content: this.$t(`products.${element.product}`) },
            { content: this.$t(`workflow.${element.state_type}`), styles: styles },
            { content: this.getCondensedTime(element.created_at) },
          ];

          rows.push(temp);
        });
    }

    return rows;
  }

  generatePDFHeaders(doc: jsPDF, deviceGroupName: string) {
    console.log(doc.getFont());
    const yOffset = 10;
    doc.setFontSize(16);
    doc.text(`${deviceGroupName.toUpperCase()} INTEGRATIONS`, 10, yOffset);
    doc.setFontSize(10);

    doc.setFont('Helvetica', 'bold').text('Glossary', 10, yOffset + 10);
    doc
      .text('B:', 15, yOffset + 16)
      .setFont('Helvetica', 'normal')
      .text('Brand', 20, yOffset + 16)
      .setFont('Helvetica', 'bold')
      .text('A:', 37, yOffset + 16)
      .setFont('Helvetica', 'normal')
      .text('Android', 42, yOffset + 16)
      .setFont('Helvetica', 'bold')
      .text('Br:', 57, yOffset + 16)
      .setFont('Helvetica', 'normal')
      .text('Browser', 63, yOffset + 16)
      .setFont('Helvetica', 'bold');

    doc
      .text('C:', 15, yOffset + 21)
      .setFont('Helvetica', 'normal')
      .text('Channel', 20, yOffset + 21)
      .setFont('Helvetica', 'bold')
      .text('I:', 37, yOffset + 21)
      .setFont('Helvetica', 'normal')
      .text('IOS', 42, yOffset + 21)
      .setFont('Helvetica', 'bold')
      .text('CL:', 57, yOffset + 21)
      .setFont('Helvetica', 'normal')
      .text('Cloud', 63, yOffset + 21);

    doc.setFont('Helvetica', 'normal');

    doc.setFillColor(253, 195, 159);
    doc.circle(200, yOffset + 15, 2, 'F');
    doc.text('Plan', 205, yOffset + 16);

    doc.setFillColor(119, 189, 231);
    doc.circle(200, yOffset + 21, 2, 'F');
    doc.text('Certifying', 205, yOffset + 22);

    doc.setFillColor(135, 214, 146);
    doc.circle(230, yOffset + 15, 2, 'F');
    doc.text('Complete / Live', 235, yOffset + 16);

    doc.setFillColor(252, 179, 70);
    doc.circle(230, yOffset + 21, 2, 'F');
    doc.text('Recertifying', 235, yOffset + 22);

    doc.setFillColor(255, 25, 25);
    doc.circle(265, yOffset + 15, 2, 'F');
    doc.text('Inactive', 270, yOffset + 16);
  }

  async createPDF(sortBy: string) {
    this._createPDF(sortBy);
  }

  async _createPDF(sortBy: string) {
    this.loading = true;
    const columns = [
      'Integration Name',
      'Parent',
      'Client',
      'Device Type | OS',
      'Country',
      'Product',
      'Current State',
      'Last Updated',
    ];
    const pdfName = `FOX_EXPORT_${this.$moment().format(defaultDateTimeFormat)}`;
    const doc = new jsPDF({ orientation: 'p', format: 'a3' });
    doc.setFontSize(8);
    let xMargin = 30;
    let yMargin = 30;
    let pageWidth = doc.internal.pageSize.width - 60;
    let pageHeight = doc.internal.pageSize.height - 150;

    const el = document.getElementById('print');
    // add option type to get the image version
    // if not provided the promise will return
    // the canvas.
    const options = {
      type: 'dataURL',
      backgroundColor: '#394456',
    };
    // @ts-ignore
    const output = await this.$html2canvas(el, options);

    const adjustPageStyle = this.report.charts.bar && this.report.charts.bar.length > 2;

    if (adjustPageStyle) {
      xMargin = 15;
      yMargin = 15;
      pageWidth = doc.internal.pageSize.width - 30;
      pageHeight = doc.internal.pageSize.height - 60;
    }

    doc.addImage(output, 'PNG', xMargin, yMargin, pageWidth, pageHeight, 'chart', 'FAST');

    Object.keys(this.individualDeviceTypes).forEach(deviceGroupName => {
      const body = this.preparePDFRows(this.individualDeviceTypes, deviceGroupName, sortBy);

      if (body && body.length) {
        doc.addPage('a4', 'landscape');

        this.generatePDFHeaders(doc, deviceGroupName);

        autoTable(doc, {
          columns,
          // @ts-ignore
          body,
          startY: 40,
          horizontalPageBreak: false,
          showHead: 'everyPage',
          theme: 'grid',
          styles: {
            cellWidth: 'wrap',
            fontSize: 8,
          },
          columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 'auto' },
          },
          headStyles: {
            fillColor: '#2980ba',
          },
        });
      }
    });

    doc.save(pdfName + '.pdf');
    this.loading = false;
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('export', {
      event_category: 'report',
      event_label: 'export report',
    });
  }
  getCondensedTime(time: string) {
    return this.$moment(time).format('ll');
  }
  osToCharacter(os: string) {
    let str;
    switch (os.toLowerCase()) {
      case 'android':
        str = '(a)';
        break;
      case 'ios':
        str = '(i)';
        break;
      case 'browser':
        str = '(br)';
        break;
      case 'cloud':
        str = '(cl)';
        break;
      default:
        str = 'na';
        break;
    }
    return str;
  }
  getStyles(element: { [key: string]: string }) {
    const style = { fillColor: '' };
    const certifyingStates = [
      'WorkflowStates::DevSanity',
      'WorkflowStates::QualityAssurance',
      'WorkflowStates::CertReview',
      'WorkflowStates::BuildApproved',
      'WorkflowStates::PostLaunchVerification',
    ];

    if (certifyingStates.indexOf(element.state_type) > -1) {
      style.fillColor = '#77BDE7';
    } else if (element.state_type == 'WorkflowStates::Planned') {
      style.fillColor = '#FDC39F';
    } else if (element.state_type == 'WorkflowStates::Production') {
      style.fillColor = '#87D692';
    }

    if (
      parseInt(element.recert_reference) &&
      parseInt(element.recert_reference) >= 0 &&
      element.state_type != 'WorkflowStates::Production'
    ) {
      style.fillColor = '#fcb346';
    }

    if (element.status == 'inactive') {
      style.fillColor = '#ff1919';
    }

    return style;
  }
}
