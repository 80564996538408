




































































import { mixins } from 'vue-class-component';
import { Date } from '@/mixins/date';
import { ROUTER } from '@/constants';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ methods: { getCssClassFromState } })
export default class CertificationTable extends mixins(Date) {
  @Prop({ required: false, default: () => [] })
  items!: unknown[];

  @Prop({ default: false })
  showAll!: boolean;

  search = '';

  glossary = [
    { abbr: 'B: ', full: 'Brand' },
    { abbr: 'C: ', full: 'Channel' },
    { abbr: 'A: ', full: 'Android' },
    { abbr: 'I: ', full: 'iOS' },
    { abbr: 'Br: ', full: 'Browser' },
    { abbr: 'Cl: ', full: 'Cloud' },
  ];

  get headers(): unknown[] {
    return [
      {
        text: this.$t('integrationName'),
        align: 'start',
        sortable: true,
        value: 'name',
        divider: false,
      },
      {
        text: this.$t('parent'),
        align: 'start',
        sortable: true,
        value: 'parent_name',
        divider: false,
      },
      {
        text: this.$t('clientId') + ' | ' + this.$t('vcId'),
        align: 'start',
        sortable: true,
        value: 'client_id',
        divider: false,
      },
      {
        text: this.$tc('deviceType', 1) + ' | ' + this.$tc('os'),
        align: 'start',
        sortable: true,
        value: 'device_type_name',
        divider: false,
      },
      {
        text: this.$t('country'),
        align: 'start',
        sortable: true,
        value: 'region',
        divider: false,
      },
      {
        text: this.$tc('product', 1),
        align: 'start',
        sortable: true,
        value: 'product',
        divider: false,
      },
      {
        text: this.$t('currentState'),
        align: 'start',
        sortable: false,
        value: 'state_type',
        divider: false,
      },
      {
        text: this.$t('last_updated'),
        align: 'start',
        sortable: true,
        value: 'created_at',
        divider: false,
      },
    ];
  }
  handleRowClick(row: { integration_id: number }) {
    this.$router.push({ name: ROUTER.WORKFLOW_LIST, params: { integrationId: row.integration_id.toString() } });
  }
  osToCharacter(os: string) {
    let str;
    switch (os.toLowerCase()) {
      case 'android':
        str = '(a)';
        break;
      case 'ios':
        str = '(i)';
        break;
      case 'browser':
        str = '(br)';
        break;
      case 'cloud':
        str = '(cl)';
        break;
      default:
        str = 'na';
        break;
    }
    return str;
  }
  getCondensedTime(time: string) {
    return this.$moment(time).format('ll');
  }
}
