































































import { thisYearDates } from '@/helpers/reportingHelper';
import { ReportActions } from '@/store/report/actions.report';
import { CoreActions } from '@/store/core/actions.core';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { ReportType } from '@/store/report/report';

import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { ActionMethod, MutationMethod } from 'vuex';

import { Action, Getter, Mutation } from 'vuex-class';
import CertificationTable from './CertificationTable.vue';
import PrintDialog from './PrintDialog.vue';

import ReportResult from './ReportResult.vue';

@Component({
  components: { ReportResult, CertificationTable, PrintDialog },
  methods: {},
})
export default class ReportResultTabs extends Vue {
  selectedTab = 0;
  certifications: unknown[] = [];

  @Watch('report', { immediate: true })
  onReportChange() {
    this.certifications = [];
    if (this.report && !this.noDataCheck) {
      this.certifications = [...this.report.certifications];
    }
  }

  @Mutation(`report/${ReportMutations.SET_SELECTED_DATE}`)
  setSelectedDate!: MutationMethod;

  setDefaultFilters() {
    this.setSelectedDate(thisYearDates());
    this.performFetchReport();
  }

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  @Action(`core/${CoreActions.GET_COUNTRY_FOR_CODE}`)
  performGetCountryByCode!: ActionMethod;

  @Getter(`report/${ReportGetter.GET_LOADING}`)
  loading!: boolean;
  @Getter(`report/${ReportGetter.GET_REPORT}`)
  report!: ReportType;

  get noDataCheck() {
    return this.report && Object.keys(this.report).length == 0;
  }
}
