















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class FilterHeader extends Vue {
  @Prop() title!: string;
  @Prop() minimize!: boolean;
  expand(e: boolean) {
    this.$emit('expand', e);
  }
}
