














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NlsnTreeViewGroup, { NlsnTreeViewItemChangeEvent } from './TreeViewGroup.vue';

export interface NlsnTreeViewItem {
  id: number;
  children: NlsnTreeViewItem[];
  name: string;
}

@Component({
  components: { NlsnTreeViewGroup },
})
export default class NlsnTreeView extends Vue {
  selection: number[] = [];

  $refs!: {
    childs: NlsnTreeViewGroup[];
  };

  @Prop({ default: () => [] })
  preSelect!: number[];
  @Prop({ default: () => [] })
  items!: NlsnTreeViewItem[];
  @Prop({ default: () => '' })
  search!: string;

  get itemsFiltered(): NlsnTreeViewItem[] {
    if (this.search.length > 0) {
      const newItems: NlsnTreeViewItem[] = [];
      this.items.forEach(item => {
        const filtered = this.searchFilter(this.getCopy(item));
        if (filtered) {
          newItems.push(filtered);
        }
      });
      return newItems;
    }
    return [...this.items];
  }

  searchFilter(item: NlsnTreeViewItem) {
    const newChilds: NlsnTreeViewItem[] = [];
    if (item.children) {
      item.children.forEach(child => {
        const filteredChild = this.searchFilter(child);
        if (
          filteredChild &&
          (filteredChild.name.toLowerCase().includes(this.search) || filteredChild.children.length > 0)
        ) {
          newChilds.push(child);
        }
      });
    }
    item.children = newChilds;

    if (newChilds.length > 0 || item.name.toLowerCase().includes(this.search)) {
      return item;
    }
    return null;
  }

  getCopy(item: NlsnTreeViewItem): NlsnTreeViewItem {
    return JSON.parse(JSON.stringify(item));
  }

  selectionChanged(events: NlsnTreeViewItemChangeEvent[]) {
    events.forEach((event: NlsnTreeViewItemChangeEvent) => {
      const index = this.selection.indexOf(event.item.id);
      if (index === -1 && event.event) {
        this.selection.push(event.item.id);
      } else if (index !== -1 && !event.event) {
        this.selection.splice(index, 1);
      }
    });
    this.$emit('input', this.selection);
  }
}
