






























































































import Vue from 'vue';
import Component from 'vue-class-component';
import FilterHeader from './FilterHeader.vue';
import CommonFilterChips from './CommonFilterChips.vue';
import CommonFilterCheckbox from './CommonFilterCheckbox.vue';
import ViewMoreOrLess from './ViewMoreOrLess.vue';
import { EVENTS } from '@/constants';
import { EventBus } from '@/mixins/eventBus';
import { MutationMethod } from 'vuex';
import {
  DeviceTypeGroup,
  FetchDeviceTypeGroupsQueryVariables,
  FetchTagsQuery,
  FetchTagsQueryVariables,
  Tag,
  TagKind,
} from '@/generated/graphql';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { Getter, Action, Mutation } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { CoreActions } from '@/store/core/actions.core';
import { ActionMethod } from '@/helpers/typeHelpers';

@Component({ components: { FilterHeader, CommonFilterCheckbox, CommonFilterChips, ViewMoreOrLess } })
export default class FilterDeviceTypes extends Vue {
  @Prop({ default: false })
  expand!: boolean;

  addDialog = false;
  customGroupName = '';
  selectedCustomDevices: Tag[] = [];
  individualDeviceTypes: Tag[] = [];
  viewMore = false;

  async created() {
    this.performFetchDeviceTypeGroups({ name: '' });
    this.individualDeviceTypes = (await this.performFetchDeviceTypes({ kind: TagKind.DeviceType })).fetchTags;
  }

  @Action(`report/${ReportActions.FETCH_DEVICE_TYPE_GROUPS}`)
  performFetchDeviceTypeGroups!: ActionMethod<FetchDeviceTypeGroupsQueryVariables, unknown>;

  @Action(`core/${CoreActions.FETCH_TAGS}`)
  performFetchDeviceTypes!: ActionMethod<FetchTagsQueryVariables, FetchTagsQuery>;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod<unknown, unknown>;

  @Action(`report/${ReportActions.CREATE_CUSTOM_DEVICE_GROUP}`)
  createCustomGroup!: ActionMethod<unknown, unknown>;

  @Getter(`report/${ReportGetter.GET_DEVICE_TYPES}`)
  devices!: DeviceTypeGroup[];

  @Getter(`report/${ReportGetter.GET_SELECTED_DEVICES}`)
  _selectedDeviceGroup!: DeviceTypeGroup[];

  @Mutation(`report/${ReportMutations.SET_SELECTED_DEVICES}`)
  _setSelectedDeviceGroup!: MutationMethod;

  @Mutation(`report/${ReportMutations.REMOVE_SELECTED_DEVICE}`)
  removeSelectedDevice!: MutationMethod;

  get selectedChipsInfo() {
    return this.selectedDeviceGroup.map(s => ({ name: s.name, code: s.id }));
  }

  get selectedDeviceGroup() {
    return this._selectedDeviceGroup;
  }

  set selectedDeviceGroup(val) {
    this._setSelectedDeviceGroup(val);
    this.performFetchReport();
  }

  get individualDevices() {
    return this.individualDeviceTypes;
  }

  get list() {
    if (this.devices.length > 5 && !this.viewMore) return this.devices.slice(0, 5);
    return this.devices;
  }

  get displayViewMore() {
    return !this.viewMore && this.devices.length > this.list.length;
  }

  toggleViewMore(v: boolean) {
    this.viewMore = v;
  }

  onAddCustomGroup() {
    this.addDialog = true;
  }

  onAdd() {
    if (!this.customGroupName || !this.selectedCustomDevices?.length) {
      const errMsg = this.$t('report.filter.deviceTypes.customGroupError').toString();
      EventBus.$emit(EVENTS.GLOBAL_ALERT, new Error(errMsg));
      return;
    }

    this.createCustomGroup({
      name: this.customGroupName,
      devices: this.selectedCustomDevices.map(d => ({ id: d.id, name: d.name })),
    });
    this.addDialog = false;
    if (this.devices.length > this.list.length) {
      this.viewMore = true;
    }
  }

  onExpand(e: boolean) {
    this.$emit('toggleExpand', { expand: e, filter: 'deviceTypes' });
  }

  onCloseClick(id: string) {
    this.removeSelectedDevice(id);
    this.performFetchReport();
  }
}
