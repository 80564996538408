











import { FetchOwlExcerptQueryVariables, TreeNode } from '@/generated/graphql';
import { ActionMethod } from '@/helpers/typeHelpers';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ParentResultHash } from '@/store/report/report';

import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import NlsnTreeView from '../tree/TreeView.vue';

@Component({
  components: { NlsnTreeView },
  methods: {},
})
export default class Excerpt extends Vue {
  open: number[] = [];
  loading = false;
  selection: number[] = [];
  search = '';

  @Prop({ default: () => [] })
  preSelect!: number[];

  @Prop({ required: true })
  treeId!: number;

  @Prop({ default: () => [] })
  value!: number[];

  @Watch('treeId', { immediate: true })
  async onTreeIdChange(to: number) {
    this.loading = true;
    const payload: FetchOwlExcerptQueryVariables = {
      limit: 1000,
      id: to,
    };
    await this.performGetExcerpt(payload);
    this.loading = false;
  }

  public get selected(): number[] {
    return this.selection;
  }

  public set selected(value: number[]) {
    this.selection = value;
    this.$emit('input', value);
  }

  @Getter(`report/${ReportGetter.GET_SELECTED_PARENTS}`)
  allParents!: ParentResultHash;

  @Action(`report/${ReportActions.FETCH_EXCERPT}`)
  performGetExcerpt!: ActionMethod<FetchOwlExcerptQueryVariables, void>;

  excerpt(): TreeNode[] {
    if (this.allParents[this.treeId] && this.allParents[this.treeId].excerpt) {
      return [this.allParents[this.treeId].excerpt!];
    }
    return [];
  }
}
