























import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class DateRangePickerMenu extends Vue {
  menu = false;
  dateRange: string[] = [];

  get isDisabled() {
    return this.dateRange.length === 2;
  }

  onInput() {
    this.$emit('input', this.dateRange);
    this.menu = false;
    this.dateRange = [];
  }
}
