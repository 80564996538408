




import { Prop } from 'vue-property-decorator';

import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getStateNames } from '@/helpers/reportingHelper';
import ApexChart from 'vue-apexcharts';
import { ApexOptions } from 'apexcharts';

@Component({
  components: { ApexChart },
  methods: {},
})
export default class LineChart extends Vue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ required: true })
  labels!: string[];

  @Prop({ default: () => [] })
  series!: unknown[];

  get chartOptions(): ApexOptions {
    return {
      labels: getStateNames(),
      title: {
        text: this.title,
        align: 'left',
      },
      // colors: getStateColors(),
      theme: {
        mode: 'dark',
      },
      chart: {
        id: 'test',
        background: 'transparent',
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['black'],
        },
      },
      xaxis: {
        categories: this.labels,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
      },
      fill: {
        opacity: 1,
      },
    };
  }
}
