




















































































import DateRangePickerMenu from '@/components/common/DateRangePickerMenu.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import FilterHeader from './FilterHeader.vue';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { ReportActions } from '@/store/report/actions.report';
import { MutationMethod, ActionMethod } from 'vuex';
import { Getter, Action, Mutation } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { allTimeDates, lastMonthDates, lastYearDates, thisMonthDates, thisYearDates } from '@/helpers/reportingHelper';
import { SavedFilterDaterange } from '@/generated/graphql';
import { CUSTOM_DATE_RANGE_NAME } from '@/constants';

@Component({ components: { FilterHeader, DateRangePickerMenu } })
export default class FilterDateRange extends Vue {
  @Prop({ default: false })
  expand!: boolean;

  @Getter(`report/${ReportGetter.GET_SELECTED_DATE}`)
  _selectedDate!: SavedFilterDaterange | null;

  @Getter(`report/${ReportGetter.IS_ANY_NON_DATE_FILTER_SET}`)
  activateAllTime!: boolean;

  @Mutation(`report/${ReportMutations.SET_SELECTED_DATE}`)
  _setSelectedDate!: MutationMethod;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  onExpand(e: boolean) {
    this.$emit('toggleExpand', { expand: e, filter: 'date' });
  }

  onCustomDateRage(dateRange: string[]) {
    this.selectedDate = { startDate: dateRange[0], endDate: dateRange[1], name: CUSTOM_DATE_RANGE_NAME };
    this.$emit('toggleExpand', { expand: false, filter: 'date' });
  }

  onCloseClick() {
    this.selectedDate = thisYearDates();
  }

  get selectedDate() {
    return this._selectedDate;
  }

  set selectedDate(val) {
    this._setSelectedDate(val);
    this.performFetchReport();
  }

  get displayChips() {
    return !this.expand && this.selectedDate && Object.keys(this.selectedDate).length;
  }

  get allTime() {
    return allTimeDates();
  }

  get lastMonth() {
    return lastMonthDates();
  }

  get thisMonth() {
    return thisMonthDates();
  }

  get lastYear() {
    return lastYearDates();
  }

  get thisYear() {
    return thisYearDates();
  }
}
