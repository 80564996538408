



























import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import Vue from 'vue';
import Component from 'vue-class-component';
import { MutationMethod } from 'vuex';
import { Getter, Mutation } from 'vuex-class';
import LoadFilterDialog from './LoadFilterDialog.vue';
import SaveFilterDialog from './SaveFilterDialog.vue';

@Component({ components: { SaveFilterDialog, LoadFilterDialog } })
export default class FilterMenu extends Vue {
  saveFilterDialog = false;

  @Getter(`report/${ReportGetter.GET_REPORT}`)
  report: unknown;
  @Mutation(`report/${ReportMutations.SHOW_EXPORT_DIALOG}`)
  showExportDialog!: MutationMethod;

  @Mutation(`report/${ReportMutations.SHOW_SAVE_FILTER_DIALOG}`)
  showSaveFilterDialog!: MutationMethod;
  @Mutation(`report/${ReportMutations.SHOW_LOAD_FILTER_DIALOG}`)
  showLoadFilterDialog!: MutationMethod;
}
