
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ViewMoreOrLess extends Vue {
  @Prop({ default: false })
  displayViewLess!: boolean;

  @Prop({ default: false })
  displayViewMore!: boolean;

  toggleViewMore(v: boolean) {
    this.$emit('toggle', v);
  }
}
