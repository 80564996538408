












import Vue from 'vue';
import Component from 'vue-class-component';
import FilterHeader from './FilterHeader.vue';
import CommonFilterCheckbox from './CommonFilterCheckbox.vue';
import { MutationMethod, ActionMethod } from 'vuex';
import { Product } from '@/generated/graphql';
import { TranslateResult } from 'vue-i18n';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { Action, Getter, Mutation } from 'vuex-class';
import { getProductArray } from '@/helpers/productHelper';
import { Prop } from 'vue-property-decorator';

@Component({ components: { FilterHeader, CommonFilterCheckbox } })
export default class FilterProducts extends Vue {
  products = getProductArray();

  @Prop({ default: false })
  expand!: boolean;

  @Getter(`report/${ReportGetter.GET_SELECTED_PRODUCTS}`)
  selectedProduct!: { code: Product; name: TranslateResult }[];

  @Mutation(`report/${ReportMutations.SET_SELECTED_PRODUCTS}`)
  setSelectedProduct!: MutationMethod;

  @Mutation(`report/${ReportMutations.REMOVE_SELECTED_PRODUCT}`)
  removeSelectedProduct!: MutationMethod;

  @Action(`report/${ReportActions.FETCH_REPORT}`)
  performFetchReport!: ActionMethod;

  onExpand(e: boolean) {
    this.$emit('toggleExpand', { expand: e, filter: 'products' });
  }

  onCloseClick(code: string) {
    this.removeSelectedProduct(code);
    this.performFetchReport();
  }
}
