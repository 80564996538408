




















































import {
  CreateReportFiltersMutation,
  CreateReportFiltersMutationVariables,
  ReportFilter,
  UpdateReportFiltersMutation,
  UpdateReportFiltersMutationVariables,
  User,
} from '@/generated/graphql';
import { ActionMethod } from '@/helpers/typeHelpers';
import { ReportActions } from '@/store/report/actions.report';
import { ReportGetter } from '@/store/report/getter.report';
import { ReportMutations } from '@/store/report/mutations.report';
import { UserGetter } from '@/store/user/getters.user';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

@Component
export default class SaveFilterDialog extends Vue {
  filterName = '';
  loading = false;
  saveAsNew = false;
  error = false;
  errorMessage = '';
  disableUpdate = false;

  @Getter(`report/${ReportGetter.GET_LOADED_FILTER}`)
  loadedFilter!: ReportFilter;

  @Getter(`report/${ReportGetter.SHOW_SAVE_FILTER_DIALOG}`)
  show!: boolean;

  @Getter(`user/${UserGetter.GET_USER}`)
  user!: User;

  @Mutation(`report/${ReportMutations.SHOW_SAVE_FILTER_DIALOG}`)
  showDialog!: MutationMethod;

  @Action(`report/${ReportActions.SAVE_REPORT_FILTERS}`)
  performSaveFilters!: ActionMethod<CreateReportFiltersMutationVariables, CreateReportFiltersMutation>;
  @Action(`report/${ReportActions.UPDATE_REPORT_FILTERS}`)
  performUpdateFilters!: ActionMethod<UpdateReportFiltersMutationVariables, UpdateReportFiltersMutation>;

  @Watch('show')
  onShow() {
    if (!this.show) return;
    if (this.loadedFilter) {
      this.filterName = this.loadedFilter.name!;
      if (this.loadedFilter.email !== this.user.email) {
        this.disableUpdate = true;
        this.saveAsNew = true;
      }
    }
  }
  async onUpdateFilter() {
    this.loading = true;
    await this.performUpdateFilters({ data: { id: this.loadedFilter.id, name: this.filterName } });
    this.reset();
  }
  async onSaveFilter() {
    this.loading = true;
    const response = await this.performSaveFilters({ data: { name: this.filterName } });
    if (!response) {
      this.error = true;
      this.errorMessage = this.$t('report.filter.saveFilterError').toString();
      this.loading = false;
    } else {
      this.reset();
    }
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('save_filter', {
      event_category: 'report',
      event_label: 'save report filter template',
    });
  }
  @Watch('filterName')
  onFilterNameChange() {
    this.error = false;
    this.errorMessage = '';
  }

  reset() {
    this.disableUpdate = false;
    //@ts-ignore
    this.showDialog(false);
    this.loading = false;
    this.saveAsNew = false;
    this.filterName = '';
    this.error = false;
    this.errorMessage = '';
  }
}
