














import { Integration, TreeNode } from '@/generated/graphql';
import { ReportGetter } from '@/store/report/getter.report';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

export type DataType = {
  dateRange: Array<string>;
};

@Component({
  components: {},
  methods: {},
})
export default class SearchResultTable extends Vue {
  @Prop({ default: () => '' })
  label!: string;
  @Prop()
  parents!: TreeNode[];
  headers = [
    { text: 'TreeID', align: 'start', value: 'id' },
    { text: 'Name', align: 'start', value: 'name' },
  ];

  @Getter(`report/${ReportGetter.GET_LOADING}`)
  isLoading!: boolean;

  handleClick(value: Integration) {
    this.$emit('click', value);
  }
}
