




import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getStateColors } from '@/helpers/reportingHelper';
import ApexChart from 'vue-apexcharts';
import { ApexOptions } from 'apexcharts';
import { DEFAULT_CHART_OPTIONS } from '@/constants';

@Component({
  components: { ApexChart },
  methods: {},
})
export default class BarChart extends Vue {
  @Prop({ required: true })
  labels!: string[];
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  group!: string;

  @Prop({ default: () => [] })
  series!: unknown[];

  get chartOptions(): ApexOptions {
    return {
      ...DEFAULT_CHART_OPTIONS,
      title: {
        text: this.$t(`osNames.${this.title}`).toString(),
        align: 'left',
      },
      colors: getStateColors(),
      theme: {
        mode: 'dark',
      },
      chart: {
        id: `bar-${this.title}`,
        group: this.group,
        background: 'transparent',
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 1400,
          options: {
            legend: {
              show: false,
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.labels.map(s => this.$t(`products.${s}`)),
      },
      yaxis: {
        labels: {
          minWidth: 20,
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        offsetY: 0,
      },
      fill: {
        opacity: 1,
      },
    };
  }
}
